import { Button, Container } from 'react-bootstrap';
import GithubIcon from '../assets/icons/GitHub-Mark-64px.png';
import ResumeIcon from '../assets/icons/icons8-resume-50.png';
import CodePenIcon from '../assets/icons/codepen.png';
import LinkedInIcon from '../assets/icons/LI-In-Bug.png';

const Home = () => {
  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center justify-content-center text-light"
      style={{
        height: '100vh',
        // background: 'linear-gradient(62deg, #28313a 0%, #171618 100%)'
      }}
    >
      <h1 
        className="text-center display-1 mb-3"
        style={{ fontSize: '7rem' }}
      >
        Andrew J Salas
      </h1>
      <h3 
        className="text-center mb-3 text-info"
        style={{ fontSize: '2.2rem' }}
      >
        Front End Developer | Sound Engineer
      </h3>

      {/* Buttons */}
      <div className="d-flex flex-wrap justify-content-center mt-4">
        {/* Github */}
        <Button
          variant="light"
          href="https://github.com/andrewjsalas"
          target="_blank"
          className="m-3 px-4 py-3 d-flex align-items-center shadow"
          style={{ fontSize: '1.5rem' }}
        >
          <span className="mr-3">Github</span>
          <img src={GithubIcon} alt="Github" height="30" className='p-1' />
        </Button>

        {/* Resume */}
        <Button
          variant="light"
          href="https://drive.google.com/file/d/1fdlNS_T9zzKzSNsJw0HQJyBDwtt4iRCK/view?usp=sharing"
          target="_blank"
          className="m-3 px-4 py-3 d-flex align-items-center shadow"
          style={{ fontSize: '1.5rem' }}
        >
          <span className="mr-3">Resume</span>
          <img src={ResumeIcon} alt="Resume" height="30" className='p-1' />
        </Button>

        {/* CodePen */}
        <Button
          variant="light"
          href="https://codepen.io/andrewjsalas"
          target="_blank"
          className="m-3 px-4 py-3 d-flex align-items-center shadow"
          style={{ fontSize: '1.5rem' }}
        >
          <span className="mr-3">CodePen</span>
          <img src={CodePenIcon} alt="CodePen" height="30" className='p-1' />
        </Button>

        {/* LinkedIn */}
        <Button
          variant="light"
          href="https://www.linkedin.com/in/andrewjsalas/"
          target="_blank"
          className="m-3 px-4 py-3 d-flex align-items-center shadow"
          style={{ fontSize: '1.5rem' }}
        >
          <span className="mr-3">LinkedIn</span>
          <img src={LinkedInIcon} alt="LinkedIn" height="30" className='p-1' />
        </Button>
      </div>
    </Container>
  );
};

export default Home;
