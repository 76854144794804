import { Container, Row, Col, Card } from 'react-bootstrap';
import JSLogo from '../assets/icons/javascript-39404.png';
import ReactLogo from '../assets/icons/React-icon-color.svg';
import PythonLogo from '../assets/icons/python_logo.png';
import ExpressLogo from '../assets/icons/express_logo.png';
import NodeLogo from '../assets/icons/node_logo.png';
import FlaskLogo from '../assets/icons/flask_logo_trans.png';
import MongoLogo from '../assets/icons/mongodb_logo.png';
import BootstrapLogo from '../assets/icons/bootstrap_logo.png';
import PostgresqlLogo from '../assets/icons/postgresql_logo.png';
import ReduxLogo from '../assets/icons/redux_logo.png';
import React from 'react';
import StyledLogo from '../assets/icons/styled-components-logo.svg';
import FirebaseLogo from '../assets/icons/firebase-logo.png';

const About = () => {
  return (
    <Container fluid className="about-container text-light py-5">
      <h1 className="text-center about-header-text">ABOUT ME</h1>

      <Container>
        <Row className="justify-content-between align-items-center">
          <Col md={6}>
            <p className='about-text fs-4'>
              Hello, my name is Andrew J Salas and I am a Software Engineer and Sound Engineer from Los Angeles, CA. In June of 2023, I completed a 10+ month Software Engineering Bootcamp from Springboard, developing skills in Front-End, Back-End, and Full-Stack Web Development along with proficiency in databases, data structures, and algorithms.
            </p>
            <br />
            <p className='about-text fs-4'>
              In addition to Software Engineering, I am also an experienced Sound Engineer with over 8 years of experience operating a vast array of audio equipment to achieve high quality and optimal sound for live events, for both small and large-scale productions. Most of my Sound Engineering work involved mixing and supervising live events at Universal Studios Hollywood: 5 Towers Events Stage and Halloween Horror Nights.
            </p>
          </Col>
          
          <Col md={6} className="d-flex">
            <Row className="gx-4 gy-4">
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={ReactLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>REACT</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={JSLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>JAVASCRIPT</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={PythonLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>PYTHON</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={FlaskLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>FLASK</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={ExpressLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>EXPRESS</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={MongoLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>MONGODB</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={BootstrapLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>BOOTSTRAP</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={PostgresqlLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>POSTGRESQL</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={ReduxLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>REDUX</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={NodeLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>NODE</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={StyledLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>STYLED COMPONENTS</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4}>
                <Card className="text-center text-light h-100 border-0 bg-transparent">
                  <Card.Img variant="top" src={FirebaseLogo} className="skills-icon" />
                  <Card.Body>
                    <Card.Text>FIREBASE</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
