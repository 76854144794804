import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import CrateDiggerThumb from '../assets/projectThumbs/crate-digger-2.png';
import DrunkenSpiritsThumb from '../assets/projectThumbs/the-drunken-spirits-screenshot.png';
import LoginForm from '../assets/projectThumbs/firebase_login.png';
import VenueThumb from '../assets/projectThumbs/venue_login_fullscreen1.png';
import HackOrSnooze from '../assets/projectThumbs/hack_or_snooze_screenshot.png';

const Projects = () => {
  return (
    <Container fluid className="text-light py-5">
      <h1 className="project-page-title text-center">Projects</h1>

      <Container>
        <Row className='gx-5 gy-4'>

          {/* Venue Capstone Project */}
          <Col md={4} className="mb-4">
            <Card 
              bg="dark" 
              text="light" 
              className="h-100 shadow d-flex flex-column shadow border-0"
            >
              <Card.Header 
                className="fs-4 text-center">
                Venue: Music Blog
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <div className="flex-grow-1">
                  <Card.Img variant="top" src={VenueThumb} className='card-thumb' />
                  <Card.Text className="mt-3">
                    A Full-Stack Twitter/Reddit inspired Music Blog where authenticated users can create a profile and submit posts about music.  
                  </Card.Text>
                  <Card.Text className='mb-3'>
                    This app utilizes the MERN stack. MongoDB, Express, React, and Node in addition with Redux, React-router, JSON Web Token, Bcrypt and styled with Bootstrap. 
                  </Card.Text>
                </div>
                <div className="mt-auto">
                  <div className="d-grid gap-2">
                    <Button 
                      variant="primary" 
                      href="https://venue-music-blog.onrender.com/" 
                      target="_blank">
                      Demo
                    </Button>
                    <Button 
                      variant="secondary" 
                      href="https://github.com/andrewjsalas/venue-capstone2" 
                      target="_blank">
                      GitHub
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Drunken Spirits App */}
          <Col md={4} className="mb-4">
            <Card 
              bg="dark" 
              text="light" 
              className="h-100 shadow d-flex flex-column border-0">
            <Card.Header 
                className="fs-4 text-center">
                Cocktail Recipe App
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <div className="flex-grow-1">
                  <Card.Img variant="top" src={DrunkenSpiritsThumb} />
                  <Card.Text className="mt-3">
                    A cocktail recipe app where the user can search a database of drink recipes, images, and more.
                  </Card.Text>
                  <Card.Text className='mb-3'>
                    Built with React, utilizing React Router, Styled Components, and The CocktailDB API.
                  </Card.Text>
                </div>
                <div className="mt-auto">
                  <div className="d-grid gap-2">
                    <Button 
                      variant="primary" 
                      href="https://the-drunken-spirits.netlify.app/" target="_blank">
                      Demo
                    </Button>
                    <Button 
                      variant="secondary" 
                      href="https://github.com/andrewjsalas/the-drunken-spirits" target="_blank">
                      GitHub
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          
          {/* Hack or Snooze */}
          <Col md={4} className="mb-4">
            <Card 
              bg="dark" 
              text="light" 
              className="h-100 shadow d-flex flex-column border-0"
            >
              <Card.Header 
                className="fs-4 text-center">
                Hacker News Clone
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <div className="flex-grow-1">
                  <Card.Img variant="top" src={HackOrSnooze} />
                  <Card.Text className="mt-3">
                    Cumulative project for Springboard Software Engineering Bootcamp. The objective was to build  Hacker News clone using JavaScript and jQuery.  
                  </Card.Text>
                  <Card.Text className='mb-3'>
                    this app utilizes the Hacker News API to retrieve all necessary data. 
                  </Card.Text>
                </div>
                <div className="mt-auto">
                  <div className="d-grid gap-2">
                    <Button 
                      variant="primary" 
                      href="https://venue-music-blog.onrender.com/" 
                      target="_blank">
                      Demo
                    </Button>
                    <Button 
                      variant="secondary" 
                      href="https://github.com/andrewjsalas/venue-capstone2" 
                      target="_blank">
                      GitHub
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Crate Digger */}
          <Col md={4} className="mb-4">
            <Card 
              bg="dark" 
              text="light" 
              className="h-100 shadow d-flex flex-column border-0">
              <Card.Header 
                className="fs-4 text-center">
                Crate Digger
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <div className="flex-grow-1">
                  <Card.Img variant="top" src={CrateDiggerThumb} />
                  <Card.Text className="mt-3">
                    A customizable table to log your record collection along with the option to edit, delete, and cancel an unfinished edit.
                  </Card.Text>
                  <Card.Text>
                    Built with React, utilizing useState and useEffect methods.
                  </Card.Text>
                </div>
                <div className="mt-auto">
                  <div className="d-grid gap-2">
                    <Button 
                      variant="primary" 
                      href="https://salas-crate-digger-2.netlify.app/" target="_blank">
                      Demo
                    </Button>
                    <Button 
                      variant="secondary" 
                      href="https://github.com/andrewjsalas/crate-digger-2" target="_blank">
                      GitHub
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Firebase Login Form */}
          <Col md={4} className="mb-4">
            <Card 
              bg="dark" 
              text="light" 
              className="h-100 shadow d-flex flex-column border-0">
              <Card.Header 
                className="fs-4 text-center">
                Firebase Form
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <div className="flex-grow-1">
                  <Card.Img variant="top" src={LoginForm} />
                  <Card.Text className="mt-3">
                    A Login and Signup form utilizing Firebase for authentication and Google Auth for users who want to signup and login using their Gmail accounts. 
                  </Card.Text>
                  <Card.Text className='mb-3'>
                    Built with React, React-router, and styled with Bootstrap.
                  </Card.Text>
                </div>
                <div className="mt-auto">
                  <div className="d-grid gap-2">
                    <Button 
                      variant="primary"
                      href="https://ajs-login-form.netlify.app/" 
                      target="_blank">
                      Demo
                    </Button>
                    <Button 
                      variant="secondary" 
                      href="https://github.com/andrewjsalas/react-login-signup" target="_blank">
                      GitHub
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Projects;
