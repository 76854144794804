import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import SiteLogo from '../assets/icons/Front end.png';
import LogoOne from '../assets/personalLogos/4.png'
import LogoTwo from '../assets/personalLogos/7.png'
import LogoThree from '../assets/personalLogos/8.png'

const CustomNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Navbar expand="lg" fixed="top" className='navbar nav-logo' data-bs-theme="dark">
      <Container className='fs-4'>
        <Navbar.Brand as={Link} to="/">
          <img 
            src={LogoOne} 
            alt="" height="65" 
            className="d-inline-block align-top" 
          />
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="responsive-navbar-nav" 
          onClick={() => setIsOpen(!isOpen)} 
        />
        <Navbar.Collapse 
          id="responsive-navbar-nav" 
          className={isOpen ? 'show' : ''}
        >
          <Nav className="ms-auto">
            <Nav.Link 
              as={Link} 
              to="/about" 
              className="nav-link">
              About
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/projects" 
              className="nav-link">
              Projects
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/sound" className="mr-3">Sound & Music</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
